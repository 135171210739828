import logo from './logo.svg';
import './App.css';
import axios from "axios"
import {BrowserRouter, Route, NavLink, Routes} from "react-router-dom";
import {useState,useCallback, useEffect} from 'react'

function App() {
  localStorage.setItem("hui","ks")
  let body=document.getElementsByTagName('body')[0]
  body.style.backgroundImage="url('https://obuka.pw/AFS/img/3.png')"
  body.style.backgroundRepeat="no-repeat"
  body.style.backgroundSize="100%"
  const MyProjects=()=>{
    let body=document.getElementsByTagName('body')[0]
    body.style.backgroundImage="none"
    body.style.backgroundColor="black"
    const [update,setUpdate]=useState('')
    
     
    useEffect(()=>{
      setUpdate('update')
    })

    const ComponentProj=(projec)=>{
      if (!localStorage.getItem(projec.projec.titleProject)){
        localStorage.setItem(projec.projec.titleProject,'')
        
      }
      
    const [loader,setLoader]=useState(Number(localStorage.getItem(projec.projec.titleProject)))
     
      


useEffect(()=>{
  setTimeout(()=>{
    
    setLoader(loader+1)
    localStorage.setItem(projec.projec.titleProject,String(loader))
   
  },projec.projec.tags.split(/\r?\n/).length*360000)

  const ObjectLoad={
    loader,user:JSON.parse(localStorage.getItem('info')).login, projecto:projec.projec.titleProject
  }
 axios.post('https://kworkapp2.vercel.app/loadingUpdate',ObjectLoad,{
    headers:{
      'Content-Type': 'application/json'
    }
  })
.then(res=>{
  
  localStorage.setItem('info',JSON.stringify(res.data.user2))
  
})
},[loader])



      return <div style={{display:"flex"}}><div className="projectOne">{projec.projec.titleProject} </div><div style={{
       
        backgroundColor:"#004aad",
        borderRadius:"100px",
        width:"240px",
        height:"35px",
        alignSelf:"flex-end",
        marginLeft:"30px",
        fontWeight:100,
        
      }}>{projec.projec.isReady==true ? <a rel={
        'external'
        } 
            target="_blank" href={projec.projec.download} ><div style={{
              position:"relative",
              top:"-5px"
            }}> Скачать</div></a> : loader>=99 ? <div style={{
              position:"relative",
              top:"-5px"
            }}>Загрузка...</div> : <div style={{
              position:"relative",
              top:"-5px"
            }}>Загрузка...</div>}</div></div>
    }
    if (localStorage.getItem('info')){
    return(
  <div class="mainprojectoo">
    <div className="projectsoft">AUDIENCE FINDER SYSTEM</div>
    <div><NavLink exact to="/"><img style={{
       position:"absolute",
       left:"0px",
       top:"40px"
      }} src="https://obuka.pw/AFS/img/4.png" alt="" /></NavLink></div>
    <div style={{
      display:"flex"
      
    }}>
    
    <div className="projectsyes">
             {JSON.parse(localStorage.getItem('info')).project.map((proj)=>{
              return <ComponentProj projec={proj}/>
             }).reverse()}
             </div></div><br /><br />
             <div style={{
               color:"white",
               fontSize:"35px",
               position:"relative",
               top:"120%",
               transform:"translate(0px,40px)",
               left:"-500px",
               fontWeight:100,
               
             }}>AFS ver. 1.0</div>
           </div>
    )}
    else{
      return(
        <div><NavLink exact to="/">Войдите в систему</NavLink></div>
      )
    }
  }
      const CreateProject=()=>{
  let body2=document.getElementsByTagName('body')[0]
  body2.style.backgroundImage="none"
  body2.style.backgroundColor="black"
  const [update,setUpdate]=useState('')
        const [project,setProject]=useState({
      titleProject:"",
      tags:"",
      whereCollect:"",
      whatCollect:"",
      amountOfPages:"",
      prox:true,
      loading:0,
      isReady:false,
      download:""
   
    })
    useEffect(()=>{
      setUpdate('update')
    })
    const [email,setEmail]=useState('')
    const [yandex,setYandex]=useState('')
    const [vk,setVk]=useState('')
    const [telegram,setTelegram]=useState('')
    const [google,setGoogle]=useState('')
    const [yandexActive,setYandexActive]=useState(false)
    const [googleActive,setGoogleActive]=useState(false)
    const [emailActive,setEmailActive]=useState(false)
    const [vkActive,setVkActive]=useState(false)
    const [telegramActive,setTelegramActive]=useState(false)

    const sendCreatedProject=useCallback(async()=>{
      
      const objectInfo={
        user:JSON.parse(localStorage.getItem('info')).login,
        titleProject:project.titleProject,
        tags:project.tags,
        whereCollect:yandex + ' ' + google,
        whatCollect:`${vk} ${telegram} ${email}`,
        amountOfPages:project.amountOfPages,
        prox:project.prox,
        loading:project.loading,
        isReady:project.isReady,
        download:project.download
      }
      console.log(objectInfo)
       await axios.post('https://kworkapp2.vercel.app/createProject',objectInfo,{
    headers:{
      'Content-Type': 'application/json'
    }
  })
.then(res=>{
  setProject({
      titleProject:"",
      tags:"",
      whereCollect:"",
      whatCollect:"",
      amountOfPages:"",
      prox:true,
      loading:0,
      isReady:false,
      download:""
  })
  localStorage.setItem('info',JSON.stringify(res.data))
  setTimeout(()=>{
// window.open('/myprojects')
window.location.href="/myprojects"
  },1000)
})
    })
    
        if (localStorage.getItem('info')){
  return(
    <div class="maincreate">
      <div className="mainCreateSoft" style={{
        display:"flex",
      
      }}><div><NavLink exact to="/"><img style={{
       position:"relative",
       left:"-400px"
      }} src="https://obuka.pw/AFS/img/4.png" alt="" /></NavLink></div><div style={{
        alignSelf:'center'
      }}>AUDIENCE FINDER SYSTEM</div></div>
      <div className="mainOne">
      <div className="oneth">
        <div>
             Название проекта: <input className="inputTitle" onChange={(e)=>{
               setProject(prev=>({
                 ...prev,
                 titleProject:e.target.value
               }))
               
             }} type="text" /></div>
             <div style={{
               display:"flex",
               flexDirection:"column",
               gap:"10px"
             }}><div style={{alignSelf:"flex-start"}}>Ключевые слова: </div><textarea className="inputTag" type="text" onChange={(e)=>{
               setProject(prev=>({
                 ...prev,
                 tags:e.target.value
               }))
               
             }} /></div>
             <div style={{
               display:"flex",
              gap:"30px"
             }}>
             <div style={{
               display:"flex",
               flexDirection:"column"
             }}>
               <div style={{
                 display:"flex",
                 justifyContent:"flex-start"
               }}>
             Где собирать:</div> <div style={{
               display:"flex",
               gap:"15px"
             }}><div style={{
               display:"flex",
               flexDirection:"column",
               fontSize:"25px"
               
               
             }}>Yandex<input checked={yandexActive} onChange={(e)=>{
             
            }}type="checkbox" value="Yandex"/> <span id="yandexSpan" style={{
            alignSelf:"center"
            }} onClick={()=>{
              if (!yandexActive){
                setYandex('Yandex')
                
              } else {
               
               setYandex('')
                
              }
            
              setYandexActive(!yandexActive)
              
            }}></span></div><div style={{
              display:"flex",
              flexDirection:"column",
              fontSize:"25px"
            }}>Google<input type="checkbox" value="Google" checked={googleActive} onChange={(e)=>{
             
            }} /> <span style={{
              alignSelf:"center"
              }} onClick={()=>{
              setGoogleActive(!googleActive)
              if (!googleActive){
                setGoogle('Google')
              } else {
               
               setGoogle('')
                
              }
            }}></span></div></div></div>
            <div>
              <div style={{
                display:"flex",
                justifyContent:"flex-start"
              }}>
             Что собирать:</div> <div  style={{
                display:"flex",
                gap:'15px'
              }}><div style={{
               display:"flex",
               flexDirection:"column",
               fontSize:"25px"
             }}>E-mail<input type="checkbox" checked={emailActive} value="E-mail" onChange={(e)=>{
            
            }} /> <span style={{
              alignSelf:"center"
              }} onClick={()=>{
              
              setEmailActive(!emailActive)
              if (!emailActive){
                setEmail('Email')
              } else {
               
               setEmail('')
                
              }
            }}></span></div><div style={{
              display:"flex",
              flexDirection:"column",
              fontSize:"25px"
            }}>Vk<input type="checkbox" checked={vkActive} value="Vk" onChange={(e)=>{
             
            }}/> <span style={{
              alignSelf:"center"
              }} onClick={()=>{
              setVkActive(!vkActive)
              if (!vkActive){
                setVk('Vk')
              } else {
               
               setVk('')
                
              }
            }}></span></div><div style={{
              display:"flex",
              flexDirection:"column",
              fontSize:"25px"
            }}>Telegram<input type="checkbox" checked={telegramActive}value="Telegram" onChange={(e)=>{
            
            }}/> <span  style={{
              alignSelf:"center"
              }} onClick={()=>{
              setTelegramActive(!telegramActive)
              if (!telegramActive){
                setTelegram('Telegram')
              } else {
               
               setTelegram('')
                
              }
            }}></span></div><br />
            </div>
            </div>
            </div>
            </div>
            <div className="twoth">
              <div>
            Кол-во страниц: <input type="text" className="inputAmount" onChange={(e)=>{
              setProject(prev=>({
                ...prev,
                amountOfPages:e.target.value
              }))
            }} /> </div>
            <div className="proxyand">
              <div style={{
                display:"flex",
                gap:"145px",
                justifyContent:"center"
              }}>
             Proxy: <img src="https://obuka.pw/AFS/img/2.png" alt="" /></div>
             <button className="submit" onClick={sendCreatedProject}>Запуск</button>
             </div>
             </div>
             </div>
             <div style={{
               color:"white",
               fontSize:"25px",
               position:"relative",
               top:"40px",
               left:"-430px",
               fontWeight:100
             }}>AFS ver. 1.0</div>
           </div>
  )}
  else{
    return(
      <div><NavLink exact to="/">Войдите в систему</NavLink></div>
    )
  }
      }
  const MainPage=()=>{
    if (localStorage.getItem('url')){
      console.log('ok')
    }
    else{
      localStorage.setItem("url","")
    }
    const [login,setLogin]=useState("")
    const [password,setPassword]=useState("")
    const [update,setUpdate]=useState('')
    const [url,setUrl]=useState("")
    const [urlInst,setUrlInst]=useState(localStorage.getItem("url"))
    let body=document.getElementsByTagName('body')[0]
    body.style.backgroundImage="url('https://obuka.pw/AFS/img/3.png')"
    body.style.backgroundRepeat="no-repeat"
  body.style.backgroundSize="100%"
    useEffect(()=>{
      setUpdate('update')
    })
    

useEffect(()=>{
   axios.post('https://kworkapp2.vercel.app/getInstruc',{
    headers:{
      'Content-Type': 'application/json'
    }
  })
.then(res=>{
  setUrlInst(res.data.url)
  localStorage.setItem("url",res.data.url)
  
})
})



    const signAcc=useCallback(async()=>{
      const ObjectInfo={
        login,password
      }
      console.log(ObjectInfo)
      await axios.post('https://kworkapp2.vercel.app/signAccount',ObjectInfo,{
        headers:{
          'Content-Type': 'application/json'
        }
      })
    .then(res=>{
      
      console.log(res.data)
      if (res.data.login){
        localStorage.setItem('info',JSON.stringify(res.data))
        setTimeout(()=>{
        window.location.reload()
      },1000)
      }
      else{
        alert(res.data.message)
      }
      
      
      
      
    })
    })

    if (localStorage.getItem('info')){
     return(
       <div>
         <div class="main">
         <div className="mainSoft">AUDIENCE FINDER SYSTEM</div>
         <NavLink exact to="/createproject"><div className="mainCreate">Создать проект</div></NavLink>
         <NavLink exact to="/myprojects"><div className="mainProjects">Мои проекты</div></NavLink>
         <a rel={
        'external'
        } 
            target="_blank" href={urlInst ? urlInst : 'нетсыылки'} ><div className="mainInstruc">Инструкции</div></a>
         <div className="exit" onClick={()=>{
           localStorage.setItem('info','')
           window.location.reload()
         }}>Выход</div>
          
         </div>
          <div style={{
               color:"white",
               fontSize:"35px",
               position:"absolute",
               top:"600px",
               left:"0px",
               fontWeight:100,
               
             }}>AFS  ver. 1.0</div>
       </div>
     )
    }
    else{
      return(
        <div>
        <div style={{
          display:"flex",
          flexDirection:"column",
          gap:"50px"
        }}>
          <div className="soft">AUDIENCE FINDER SYSTEM</div>
          <div style={{
            display:"flex",
            flexDirection:"column",
            gap:"1px",
            paddingLeft:"60px",
            alignSelf:"flex-start"
          }}><div className="loginText" >Логин <input onChange={(e)=>{
            setLogin(e.target.value)
                    }} className="loginInput" type="text" /></div><div className="passText" onChange={(e)=>{
                      setPassword(e.target.value)
                    }}>Пароль <input className="passInput" type="text" /></div></div>
        </div>
        <div className="SignIn" onClick={signAcc}>Войти</div>
        <div style={{
               color:"white",
               fontSize:"35px",
               position:"relative",
               top:"40px",
               left:"-470px",
               fontWeight:100
             }}>AFS ver. 1.0</div>
        </div>
      )
    }
   
  }
  const AdminPage=()=>{
    const [login,setLogin]=useState('')
    const [pass,setPass]=useState('')
    const[loginAdmin,setLoginadmin]=useState('')
    const[passwordAdmin,setPasswordAdmin]=useState("")
    // const [project,setProject]={
    //   titleProject:"",
    //   tags:"",
    //   whereCollect:"",
    //   whatCollect:"",
    //   amountOfPages:"",
    //   prox:true,
    //   isReady:false,
    // }
    

const createAcc=useCallback(async()=>{
  const ObjectInfo={
    login,pass
  }
  console.log(ObjectInfo)
  await axios.post('https://kworkapp2.vercel.app/createAccount',ObjectInfo,{
    headers:{
      'Content-Type': 'application/json'
    }
  })
.then(res=>{alert(res.data.message)})
})
const confirmAdmin=useCallback(async()=>{
  // let A=prompt('Введите логин')
  // let B=prompt('Введите пароль')
  
  // if (A=="admin" && B=='RY1ATripui6$'){
  //   localStorage.setItem('admin',"isActive")
  //   localStorage.setItem('usersAndTasks',JSON.stringify([]))
  //   setTimeout(()=>{
  //     window.location.reload()
  //   },1000)
  // }



 
    const ObjectInfo={
      login:loginAdmin,password:passwordAdmin
    }
    console.log(ObjectInfo)
    await axios.post('https://kworkapp2.vercel.app/adminSign',ObjectInfo,{
      headers:{
        'Content-Type': 'application/json'
      }
    })
  .then(res=>{
    
     localStorage.setItem('admin',res.data)
    localStorage.setItem('usersAndTasks',JSON.stringify([]))
    setTimeout(()=>{
      window.location.reload()
    },1000)
  console.log(res.data)
    
    
    
  })
  








})


const Task=(obj)=>{
  console.log(obj)
  console.log(obj.obj.login)
const Zadanie=(inf,obje)=>{
  const [linkReady,setLinkReady]=useState('')
  console.log("обже",inf.obje.login)
 
  const sendReady=useCallback(async()=>{
    console.log(obje)
    const readyLink={
      linkReady,obje:inf.obje.login,inf:inf.inf.titleProject
    }
    await axios.post('https://kworkapp2.vercel.app/ready',readyLink,{
      headers:{
        'Content-Type': 'application/json'
      }
    })
  .then(res=>{
    alert('Отправлено!')
    
      axios.get('https://kworkapp2.vercel.app/allTasks',{
       headers:{
         'Content-Type': 'application/json'
       }
     }).then(res=>localStorage.setItem('usersAndTasks',JSON.stringify(res.data)))
  
})
  })
  
  console.log("Инфа",inf)
  return(
    <div>
    <div>Название проекта: {inf.inf.titleProject}</div><br />
    <div>Ключевые слова: {inf.inf.tags}</div><br />
    
    <div>Где собирать: {inf.inf.whereCollect}</div><br />
    <div>Что собирать: {inf.inf.whatCollect}</div><br />
    <div>Количество страниц: {inf.inf.amountOfPages}</div>
    <div>Готово?: {inf.inf.isReady==true ? 'Да' : "Нет" }</div>
    <div>Proxy: True</div><br />
    <input  onChange={(e)=>{
      setLinkReady(e.target.value)
    }}type="text"/> <button onClick={sendReady}>Отправить</button>
    <div></div>
    <br /></div>
  )
}
if(obj.obj.project){
  return(
    <div>
      <div>Задания пользователя под ником {obj.obj.login}:</div>
      <div>{obj.obj.project.map((e)=>{
        console.log('обьект', obj.obj)
       return <Zadanie inf={e} obje={obj.obj} />
      }).reverse()}</div><br /><br />
      </div>
  )}
  else{
    <div>Нет активных аккаунтов или заданий</div>
  }
}




useEffect(()=>{
   axios.get('https://kworkapp2.vercel.app/allTasks',{
    headers:{
      'Content-Type': 'application/json'
    }
  }).then(res=>localStorage.setItem('usersAndTasks',JSON.stringify(res.data)))
})
    let body=document.getElementsByTagName('body')[0]
  body.style.backgroundImage="none"
  body.style.backgroundColor="white"
 
  if (localStorage.getItem("admin")=="isActive"){
   

    return(
      <div>
        <div className="blockCreateAccount">
        Создать новый аккаунт <br />
      <input placeholder="Логин" onChange={(e)=>{
        setLogin(e.target.value)
      }} type="text" /><br/><input placeholder="Пароль" onChange={(e)=>{
        setPass(e.target.value)
      }} type="text" /><br/> <button onClick={createAcc}>Создать</button>
      <button onClick={()=>{
          axios.get('https://kworkapp2.vercel.app/allTasks',{
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res=>{localStorage.setItem('usersAndTasks',JSON.stringify(res.data))
        setTimeout(()=>{
          window.location.reload()
        },1000)
        })
      }}> Обновить страницу</button>
      <button onClick={()=>{
        localStorage.setItem('admin',"")
        setTimeout(()=>{
          window.location.reload()
        },1000)
      }}>Выход</button>
      </div>
      <div>
{JSON.parse(localStorage.getItem("usersAndTasks")).map((e)=>{
return <Task obj={e}/>
})}
      </div>

      </div>
    )}
    else{
      return (<div><input type="text" placeholder="Логин" onChange={(e)=>{
setLoginadmin(e.target.value)
      }} /><br /><input type="text" placeholder="Пароль" onChange={(e)=>{
        setPasswordAdmin(e.target.value)
              }}/><br /> <div onClick={confirmAdmin}><h1 className="enter">Войти</h1></div></div>)
    }
  }
  const NotFound=()=>{
    
    return <h1>404</h1>
  }
  return (
    <div className="App">
      <BrowserRouter>
    
    <Routes>
    <Route  exact path='admin' element={<AdminPage/>} />
    <Route exact path="/" element={<MainPage/>} />
    <Route exact path="myprojects" element={<MyProjects/>} />
    <Route exact path="createproject" element={<CreateProject/>} />
    <Route exact path='*' element={<NotFound />} />
    </Routes>
   
    </BrowserRouter>
    </div>
  );
}

export default App;
